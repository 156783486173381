.root {
    display: none;
}

@media (--snow-tablet) {
    .root {
        display: block;
        height: 100%;
        width: 100%;
    }

    .link {
        align-items: center;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        position: relative;
        text-decoration: none;
        padding: 0;
        background-color: transparent;
    }

    .link,
    .link:hover,
    .link:focus,
    .link:active,
    .link:focus:active {
        background-color: transparent;
    }

    .icon {
        height: 20px;
        width: 20px;
    }

    .iconRedesign {
        height: 24px;
        width: 24px;
    }

    .stickedIcon {
        color: #6c737f;
    }

    .stickedIconRedesign {
        color: #a0a0ab;
    }

    .amount {
        background-color: #18181b;
        border-radius: 6px;
        height: 20px;
        padding: 0 4px;
        position: absolute;
        right: -1px;
        top: -5px;
        z-index: 2;
    }

    .stickedAmount {
        background-color: #fe2722;
    }

    .tag {
        color: #fff;
        font-family: Inter, sans-serif;
        font-size: 11px;
        font-weight: 500;
        letter-spacing: 0.01em;
        line-height: 20px;
    }

    .title {
        display: none;
        padding: 0 8px;
    }

    .titleRedesign {
        padding: 0 5px;
    }
}

@media (--snow-desktop) {
    .icon {
        height: 24px;
        width: 24px;
    }

    .title {
        color: #fff;
        display: inline;
        font-family: Inter, sans-serif;
        font-size: 11px;
        font-weight: 500;
        letter-spacing: 0.01em;
        line-height: 13px;
    }

    .title.stickedTitle {
        display: none;
    }

    .title.stickedTitleRedesign {
        display: inline;
        color: #898993;
    }

    .icon + .title {
        margin-top: 2px;
    }
}
